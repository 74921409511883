@media screen and (max-width: 1450px) {
    .home-search-position{
        bottom: 60px;
    }
}
@media screen and (max-width: 1200px) {
    .sponsor-image{
        height: auto;
    }
    .homeTopTitleSmall {
        padding-top: 187px;
    }

    .scrollBtnContainer {
        display: none;
    }

    .articleTitleTop {
        font-size: 36px;
    }

    .articleTitle {
        font-size: 20px;
    }

    .desktop-menu li a.telemedicine, .desktop-menu li a.telemedicine:hover, .desktop-menu li a.telemedicine:active, .desktop-menu li a.telemedicine:visited {

        margin-left: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .home-search-position{
        position: relative;
    }
}
@media screen and (max-width: 1199px) {
    .owl-carousel .item .slideContainer, .sliderLoading {
        min-height: 797px;
    }
}

@media screen and (max-width: 1024px) {

    .or-badge-login {
        top: 15px;
    }

    .login-title {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .login-patient-button-login, .login-patient-button-mobile {
        padding: 18px 0 0 !important;
        height: 58px !important;
    }

    .login-specialist-button {
        padding: 12px 0 0!important;
        height: 58px !important;
    }

    .searchTextList {
        height: auto !important;
        padding-bottom: 0 !important;
    }

    .faLocationIcon {
        font-size: 65px;
    }

    #locationIcon {
        padding: 0 5px;
    }

    .owl-carousel .item .slideContainer {
        min-height: 600px;
    }

    .home-search-position{
        bottom: 75px;
    }

    .home-top-title {
        padding-top: 25px;
    }
    .sliderTopText {
        height: 130px !important;
    }

    .drop-top-menu-element,.drop-top-menu-element:active,.drop-top-menu-element:visited,.drop-top-menu-element:hover{
        padding: 15px 20px!important;
    }
    .drop-top-menu-element-last,.drop-top-menu-element-last:active,.drop-top-menu-element-last:visited,.drop-top-menu-element-last:hover{
        padding: 15px 0px 15px 20px!important;
    }
    .search-dropdown-mini{
        overflow: hidden;
    }
    .how-work-text{
        padding-top: 10px;
    }
    .pad-top-0-mini-1024{
        padding-top: 0!important;
    }
    .margin-top-0-mini-1024{
        margin-top: 0!important;
    }
    .dont-have-account-text{
        padding-bottom: 10px;
    }
    .number-of-results {
        font-size: 16px;
    }
}


@media only screen and (max-width: 1000px) {
    .phone-menu-display-on{
        display: block!important;
    }
    .phone-menu-display-off{
        display: none!important;
    }

    .menu-text-box.is-visible{
        padding-top: 70px!important;
        z-index: 20;
    }

    .menu-text-box{
        list-style: none;
    }
    .menu-text{
        color: #2b8aca!important;
        font-size: 18px; /* Approximation due to font substitution */
        font-weight: 600;
        line-height: 24px; /* Approximation due to font substitution */
        text-align: left!important;
        padding:0;
        border:none!important;
    }

    .menu-text-pad{
        padding: 10px 25px !important;
    }

    .backBtn, .backBtn:link, .backBtn:active, .backBtn:visited {
        top: 65px;
    }

}

@media only screen and (max-width: 991px) {

    .home-search-position{
        bottom: 25px;
    }


    .loginContainer {
        padding-top: 30px;
    }

    .signUpFormContainer {
        padding-top: 40px;
    }

    .login-title {
        padding-top: 80px;
        padding-bottom: 30px;
    }

    .search-profile-speciality {
        padding-bottom: 10px;
    }

    .searchProfileMore {
        padding: 6px 0;
    }

    .owl-carousel .item .slideContainer {
        min-height: 750px !important;
    }

    .search-expertise-wrapper .select2 .select2-selection__arrow {
        display: none;
    }

    .cookie-policy-popup .row {
        display: block;
    }

}
@media only screen and (max-width: 960px) {

    .how-work-phone-display-off{
        display: none;
    }
    .how-work-phone-display-on{
        display: block;
    }

    .calendar-date-container, .calendar-jump-container {
        padding-top: 0;
        padding-bottom: 0;
    }

    .profile-top-bg {
        background: #CCCCCC !important;
    }

}
@media only screen and (max-width: 768px) {

    .calendar-phone-display-off {
        display: none;
    }

    .calendar-phone-display-on {
        display: block;
    }

    .searchDescription {
        height: auto !important;
    }

    .search-box{
        margin-left: -15px;
    }

    .backBtn {
        top: 85px;
    }

    .searchTextList {
        padding-top:  10px;
    }

    .home-top-section-1 {
        background: url("../image/mob1.jpg") no-repeat center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .home-top-section-2 {
        background: url("../image/mob2.jpg") no-repeat center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    /*TODO Delete*/
    .home-top-container-pad {
        padding-top: 8px !important;
    }
    .telemedicine-text {
        z-index: 2;
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 12px;
    }

}

@media only screen and (max-width: 767px) {
    .teamMemberBig {
        display: none;
    }
    .teamMemberSmall {
        display: block;
    }

    .owl-carousel .item .slideContainer {
        min-height: 700px;
    }

    .articleText {
        height: auto;
    }

    .articleTitle {
        height: auto !important;
    }
}

@media only screen and (max-width: 736px) {
    .benefits-icon-box-position{
        margin-left: 0;
        padding-left: 0;
    }

    .search-box{
        margin-left: 0;
    }
    .cd-header{
        height: 70px;
    }
    .logo-dimension{
        padding: 15px 0 0 25px;
    }

    .search-box{
        position: absolute;
        bottom: -10px;
        z-index: 2;
        width: 92%;
    }
    .search-top-title{
        padding-top: 75px;
    }
    .home-top-title{
        padding-top: 25px;
    }

    .home-top-text{
        padding-bottom: 350px;
    }
    .search-imput{
        margin: 0;
    }
    .search-button{
        margin-top:5px;
    }
    .health-care-fine-button{
        margin-bottom: 10px;
    }
    .home-text{
        padding: 10px 0;
    }
    .phone-display-on{
        display:block;
    }
    .phone-display-off{
        display: none;
    }
    .user-profile-review{

        padding: 15px 50px!important;
    }
    #review-patient .owl-prev{
        position: absolute;
        left: 0px;
        bottom: 30px;
    }
    #review-patient .owl-next{
        position: absolute;
        right: 0px;
        bottom: 30px;
    }
    #review-doctors .owl-prev{
        position: absolute;
        left: 0px;
        bottom: 30px;
    }
    #review-doctors .owl-next{
        position: absolute;
        right: 0px;
        bottom: 30px;
    }
    .pad-top-25-mini{
        padding-top: 25px;
    }
    .margin-left-team-img{
       margin-left:  40px ;
       margin-top:  10px ;
    }
    .about-title{
        padding-top: 25px;
    }
    .about-top-title{
        padding-bottom: 25px;
    }

    .search-profile-name{
        font-size: 22px;
    }
    .search-profile-distance{
        padding-top: 0;
    }
    .user-profile-review{
        padding-left: 15px;
    }
    .pad-l-15-mini{
        padding-left: 15px!important;
    }

    .profile-name-white{
        font-size: 22px;
    }
    .login-title{
        font-size: 35px!important;
        line-height: 35px;
    }
    .profile-distance-white{
        padding-top: 0;
    }
    .review-text {
        font-size: 19px;
    }
    .log-in-button{
        background: #2b8aca;
        width: 100%;
        border:1px solid #2b8aca!important;
    }
    .sign-up-button{
        background: #2b8aca;
        color: #FFFFFF!important;
        width: 100%;
        border:1px solid #2b8aca!important;
    }
    .log-in-button:hover{
        background: #FFFFFF;
        color: #2b8aca!important;
    }
    .sign-up-button:hover{
        background: #FFFFFF;
        color: #2b8aca!important;
    }

    .textReviewSmall {
        padding-left: 15px !important;
        padding-right: 15px !important;
        text-align: center!important;
    }

    #review-patient .owl-prev, #review-doctors .owl-prev {
        bottom: -20px;
        left: 50%;
        margin-left: -30px;
    }

    #review-patient .owl-next, #review-doctors .owl-next{
        bottom: -20px;
        right: 50%;
        margin-right: -30px;
    }

}
@media only screen and (max-width: 667px) {
    .review-text {
        font-size: 17px;
    }

    .or-badge {
        font-size: 8px;
        right: -12px;
    }

    .or-badge-login {
        top: 17px;
    }

    .mobile-tab-btn {
        font-size: 10px !important;
    }
    .modal-dialog{
        width: 94%;
        margin-left: 3%;
        margin-right: 3%;
    }
}


@media only screen and (max-height: 767px) {

    .owl-carousel .item .slideContainer {
        min-height: 600px;
    }

    .home-top-title {
        padding-top: 20px;
    }

    .sliderTopText {
        padding-bottom: 100px;
    }

    .searchDescription, .searchName {
        height: auto;
    }

    .search-list-view{
        padding: 6px 15px 5px 15px !important;
        margin-top: 7px;
    }
    .search-map-view{
        padding: 6px 15px 5px 15px !important;
        margin-top: 7px;
    }




}

@media only screen and (max-width: 568px) {

    .owl-carousel .item .slideContainer {
        min-height: 700px !important;
    }

    .sliderTopTitle {
        font-size: 40px;
    }

    .search-list-view{
        padding: 6px 5px 5px 5px !important;
        margin-top: 27px;
        font-size: 10px;
    }
    .search-map-view{
        padding: 6px 5px 5px 5px !important;
        margin-top: 27px;
        font-size: 10px;
    }

}
@media only screen and (max-width: 500px) {
    .owl-carousel .item .slideContainer {
        min-height: 750px !important;
    }
    .profile-top-button, .profile-top-button:focus{
        padding: 12px 12px;
    }

    .articleTitleTop {
        font-size: 26px;
    }

    .sliderTopTitle {
        padding-top: 10px;
    }

    .sliderTopText {
        padding-bottom: 0px;
    }

    .home-search-position {
        bottom: 25px;
    }

    .profile-review-button {
        font-size: 9px;
    }

}
@media only screen and (max-width: 414px) {


    .perPageTextSmall {
        display: block;
        text-align: right;
        padding-right: 0;
    }

    .perPageTextBig {
        display: none;
    }
    .sort-by-small-width {
        width: 100% !important;
    }

    .about-title{
        padding-top: 0;
    }
    .review-text {
        font-size: 14px;
    }

    .articleTopBox {
        width: 100%;
        padding-left: 0;
    }

    .sliderTopTitle {
        font-size: 36px;
    }


}
@media only screen and (max-width: 320px) {
    .home-top-title {
        font-size: 40px;
    }
    .home-top-search-text{
        font-size: 12px;
    }
    .review-text {
        font-size: 12px;
    }
    .search-profile-name {
        font-size: 18px;
    }
    .search-list-view{
        padding: 6px 5px 5px 5px !important;
        margin-top: 27px;
    }
    .search-map-view{
        padding: 6px 5px 5px 5px !important;
        margin-top: 27px;
    }

    .articleTitleTop {
        font-size: 20px;
    }
}
