
.top-menu-drop {
    color: #ffffff !important;
    background: none !important;
    border: none;
    margin-top: 7px;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 900;
    line-height: 24px; /* Approximation due to font substitution */
}

.drop-top-menu-style {
    width: 850px !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    position: absolute;
    top: 62px;
    right: -600px !important;
    background: none;
    border-bottom: 1px solid #FFFFFF;
    border-right: none;
    border-left: none;
    border-top: none;
    box-shadow: none !important;
}

.drop-top-menu-element, .drop-top-menu-element:active, .drop-top-menu-element:visited, .drop-top-menu-element:hover {
    color: #ffffff !important;
    background: none !important;
    font-size: 14px !important; /* Approximation due to font substitution */
    font-weight: 900 !important;
    line-height: 24px !important; /* Approximation due to font substitution */
    text-align: right;
    padding: 15px 35px !important;
}

.drop-top-menu-element-last, .drop-top-menu-element-last:active, .drop-top-menu-element-last:visited, .drop-top-menu-element-last:hover {
    color: #ffffff !important;
    background: none !important;
    font-size: 14px !important; /* Approximation due to font substitution */
    font-weight: 900 !important;
    line-height: 24px !important; /* Approximation due to font substitution */
    text-align: right;
    padding: 15px 0px 15px 35px !important;
}


.search-dropdown span.angle-icon-dropdown {
    position: absolute;
    right: 15px;
    top: 30px;
}

.search-imput {
    width: 100%;
    color: #8d8d8d;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.search-button:hover {
    background: #FFFFFF;
    color: #5aaa83;
    border: 1px solid #5aaa83;
}


.Newsletter-button {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #ffffff !important;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    background: #5aaa83 !important;
    padding: 12px 0 !important;
    border: 1px solid #5aaa83 !important;
}

.Newsletter-button:hover {
    background: #FFFFFF !important;
    color: #5aaa83 !important;
}

.Newsletter-form-element-i {
    color: #8d8d8d;
    font-size: 16px; /* Approximation due to font substitution */
    font-weight: 400;
    line-height: 24px; /* Approximation due to font substitution */
    text-align: left;
    height: 50px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 100% !important;
}


/*menu mini*/

body.overflow-hidden {
    /* when primary navigation is visible, the content in the background won't scroll */
    overflow: hidden;
}

.cd-header {
    position: absolute;
    top: 0;
    left: 0;
    /*background: rgba(2, 23, 37, 0.95);*/
    height: 50px;
    width: 100%;
    z-index: 100;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: none;
    border-bottom: 1px solid #FFFFFF;
}

@media only screen and (min-width: 1170px) {
    .cd-header {
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        transition: background-color 0.3s;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .cd-header.is-fixed {
        /* when the user scrolls down, we hide the header right above the viewport */
        position: fixed;
        top: -80px;
        background-color: #FFFFFF;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }

    .cd-header.is-visible {
        /* if the user changes the scrolling direction, we show the header */
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        -o-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    .cd-header.menu-is-open {
        /* add a background color to the header when the navigation is open */
        background-color: #FFFFFF;
    }
}

.cd-header .menu-is-open a {
    background: #FFFFFF !important;
}

.cd-primary-nav-trigger {
    position: absolute;
    right: 25px;
    top: 0;
    height: 100%;
    width: 50px;
}

.cd-primary-nav-trigger .cd-menu-text {
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 700;
    /* hide the text on small devices */
    display: none;
}

.cd-primary-nav-trigger .cd-menu-icon {
    /* this span is the central line of the menu icon */
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 18px;
    height: 2px;
    background-color: #FFFFFF;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;

    /* these are the upper and lower lines in the menu icon */
}

.cd-primary-nav-trigger .cd-menu-icon::before, .cd-primary-nav-trigger .cd-menu-icon:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #FFFFFF;
    right: 0;
    -webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
    -moz-transition: -moz-transform .3s, top .3s, background-color 0s;
    transition: transform .3s, top .3s, background-color 0s;
}

.cd-primary-nav-trigger .cd-menu-icon::before {
    top: -5px;
}

.cd-primary-nav-trigger .cd-menu-icon::after {
    top: 5px;
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked {
    background-color: rgba(255, 255, 255, 0);
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before, .cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
    background-color: #2b8aca;
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before {
    top: 0;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
    top: 0;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
}

@media only screen and (min-width: 768px) {
    .cd-primary-nav-trigger {
        width: 100px;
        padding-left: 1em;
        background-color: transparent;
        height: 30px;
        line-height: 30px;
        right: 2.2em;
        top: 50%;
        bottom: auto;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .cd-primary-nav-trigger .cd-menu-text {
        display: inline-block;
    }

    .cd-primary-nav-trigger .cd-menu-icon {
        left: auto;
        right: 1em;
        -webkit-transform: translateX(0) translateY(-50%);
        -moz-transform: translateX(0) translateY(-50%);
        -ms-transform: translateX(0) translateY(-50%);
        -o-transform: translateX(0) translateY(-50%);
        transform: translateX(0) translateY(-50%);
    }
}

.cd-primary-nav {
    /* by default it's hidden - on top of the viewport */
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    z-index: 200;
    text-align: left;
    padding: 50px 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: auto;
    /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.cd-primary-nav.is-visible {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}


.menu-text-drop-style {
    min-width: 100%;
}

.dropdownText {
    overflow-x: hidden;
    max-width: 100%;
    margin-right: 20px;
    padding-top: 6px;

}

.menu-drop-mini, .menu-drop-mini:hover {
    background: none !important;
    width: 100%;
}

.open .menu-drop-mini {
    background: none !important;
}

.menu-text-drop-mini {
    display: none;
    list-style: none;
    padding-left: 25px;
    width: 100% !important;
    border: none !important;
    box-shadow: none !important;
}

.menu-drop-mini-list li a {
    padding: 10px;
    display: block;
}

.menu-text-mini-hover-bg {
    padding: 15px 10px !important;
}

.menu-text-mini-hover-bg:hover {
    background: #2b8aca !important;
    color: #FFFFFF !important;
}

.menu-drop-mini span {
    font-size: 20px;
    font-weight: 900;
    position: absolute;
    right: 50px;
}

.cd-header.menu-is-open {
    border-bottom: 1px solid #2b8aca !important;
}


#expertiseSelect .dropdown-menu {
    padding: 10px;
    height: 330px;
}

#expertiseSelect .expertiseBox {
    padding: 15px 10px 15px 10px;
    border-bottom: 1px solid #e6e6e6;
}

#expertiseSelect .expertiseBox:last-child {
    border-bottom: 0px;
}

#expertiseSelect .expertiseBox .expertiseName {
    color: #3683cb;
    cursor: pointer;
}

#expertiseSelect .expertiseBox .expertiseName .expertiseCaret.sectionIsClosed {
    -webkit-transform: rotate(0deg); /* Chrome and other webkit browsers */
    -moz-transform: rotate(0deg); /* FF */
    -o-transform: rotate(0deg); /* Opera */
    -ms-transform: rotate(0deg); /* IE9 */
    transform: rotate(0deg); /* W3C compliant browsers */
}

#expertiseSelect .expertiseBox .expertiseName .expertiseCaret.sectionIsOpen {
    -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg); /* FF */
    -o-transform: rotate(180deg); /* Opera */
    -ms-transform: rotate(180deg); /* IE9 */
    transform: rotate(180deg); /* W3C compliant browsers */
}

#expertiseSelect .expertiseBox .expertiseSubspeciality {
    padding-top: 10px;
}

#expertiseSelect .expertiseBox .expertiseSubspeciality .selectSubspeciality {
    cursor: pointer;
    color: #3683cb;
    font-size: 90%;
}

#expertiseSelect .selectSubspeciality:last-of-type {
    padding-bottom: 0px;
}

#expertiseSelect #expertiseScroll {
    max-height: 300px;
    overflow: hidden;
    padding-top: 10px;
}

#insurersSelect #insurersScroll {
    overflow: hidden;
    height: 190px;
}

#insurersSelect #insurersScroll a {
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    background: #ffffff;
}

#insurersSelect #insurersScroll a:hover {
    background: #f5f5f5;
}


@keyframes pulse {
    0% {
        background-color: #2b8aca;
        color: #FFFFFF;
    }
    100% {
        background-color: #eee;
        color: #2b8aca;
    }
}


@keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@-moz-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@-webkit-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@-ms-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@-o-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}


.cookie-policy-popup {
    width: 100%;
    position: fixed;
    z-index: 99;
    padding: 1em 0;
    color: #fff;
    font-size: 14px;
    background: hsla(0, 3%, 8%, .9);
    bottom: 0;
}

.cookie-policy-popup h5 {
    font-size: 14px;
    font-weight: bold;
    color: #2b8aca;
}

.cookie-policy-popup button {
    background: transparent;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    font-size: 12px;
    margin-right: 5px;
    border-radius: 0;
    font-weight: bold;
    width: 110px;
    margin-bottom: 5px;
}

.cookie-policy-popup .row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cookie-policy-popup button:hover {
    color: #2b8aca;
    border-color: #2b8aca;
}

.search-expertise-wrapper .select2-selection.select2-selection--single {
    height: 80px;
}

.search-expertise-wrapper .select2-selection__arrow {
    width: 35px !important;
    height: 80px !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    line-height: 80px;
    color: #7d7d7d !important;
}

.search-expertise-wrapper .select2-selection__arrow b {
    display: none;
}

.search-expertise-wrapper .select2-selection__arrow:before {
    content: "\f107";
}

.search-expertise-wrapper .select2-selection__rendered {
    line-height: 80px !important;
    padding-left: 15px !important;
    color: #7d7d7d !important;
}

.search-expertise-dropdown .select2-results__option {
    color: #2b8aca;
}

.search-expertise-dropdown .expertise-item {
    padding: 5px 10px;
}

.search-expertise-dropdown .expertise-item .name {
    display: block;
}

.search-expertise-dropdown .expertise-item .suggest {
    color: #7d7d7d;
    cursor: pointer;
}

.search-expertise-dropdown .expertise-item .suggest .suggestion {
    color: #cc2127;
}

.search-expertise-dropdown .expertise-item .matches {
    color: #7d7d7d;
}

.search-expertise-dropdown .expertise-item .children {
    list-style-type: disc;
    font-size: 12px;
}

.search-expertise-dropdown .expertise-item .matches .match {
    color: #7d7d7d;
    font-size: 13px;
    text-transform: capitalize;
}

.search-expertise-dropdown .select2-results__option--highlighted {
    color: #fff !important;
    background-color: #2b8aca !important;
}

.search-expertise-dropdown .select2-results__option--highlighted .match {
    color: #fff !important;
}

.search-expertise-dropdown .select2-search.select2-search--dropdown {
    position: absolute;
    top: -77px;
    width: 90%;
    display: block;
}

.search-expertise-dropdown .select2-search.select2-search--dropdown .select2-search__field {
    position: relative;
    padding: 25px 15px;
    width: 90%;
    border: 0 !important;
    color: #7d7d7d;
}

.search-expertise-dropdown .select2-search.select2-search--dropdown .select2-search__field:focus {
    outline: none;
    border: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


.select2-results__group {
    display: none !important;
}

.search-expertise-wrapper .select2 .selection .select2-selection, #locationIcon {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.faLocationIcon {
    font-size: 35px;
    color: #2b8aca;
    cursor: pointer;
}
